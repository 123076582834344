.tag {
    display: inline;
    padding: 5px 12px 5px 8px;
     color: black;
     background-color: white;
     border: 2px solid;
     border-color:  #0069ea;
     border-radius: 25px;
     text-align: center;
     font-size: 12px;
     
  }
  
  
   .tag-img {
    position: relative;
    background: white;
    width: 16px;
    border-radius: 25px;
    justify-content: center;
    margin: 0px 6px -2px -7px;
   }