.Panel {
    position: absolute;
    cursor: default;
  z-index: 0;
    top: 0;
    right: 0;
    width: 50vw;
    min-width: 620px;
    max-width: 60vw;
    height: 100vh;
    box-sizing: border-box;
    padding: 8px;
    padding-bottom: 20px;
    overflow-x: hidden;
    overflow-y: auto;
    border-left: 1px solid #bbc5ca;
    background-color: #ffffff;
    transition-delay: 1ms;
    transition-duration: 0.55s;
    transition-timing-function: ease;
   
  }

.Step {
  position:relative;
  height:50vh
}
 