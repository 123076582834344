.Panel {
    position: fixed;
    top: 5;
    box-shadow: 0 20px 20px 4px lightgray;
   display: block;
    margin-right: 10%;
    margin-left: 15%;
    width: 80%;
   margin-top: 100px;
    max-width: 700px;
    height: 55%;
    background-color: rgba(255, 255, 255, 0.25);
    box-sizing: border-box;
    padding: 0% 0px 0px 0%;
    overflow-y: hidden;
    overflow-x: hidden;
    border: 1px solid black;
    border-radius: 8px;
    background-color: #ffffff;
    transition-delay: 1ms;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
  }

.Step {
  position:relative;
  height:50vh
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="Panel"] {
    width: 95%;
    margin-left: 2%;
    margin-right: 5%;
    overflow-x: hidden;
    
  }
}