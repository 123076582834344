.json-inspector , 
.json-inspector__selection {
  border: 0.3px;
  border-style: solid;
  
  border-color: lightgray;
  border-radius: 4px;
  font: 16px/1.4 Arial, monospace;
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

.json-inspector__leaf {
  background: whitsmoke;
  padding-left: 10px;
  border-radius: 4px;
  margin: 0px 10px 0px 10px;
}

.json-inspector__line {
  display: block;
  position: relative;
  cursor: default;
  
}

.json-inspector__line:after {
  content: "";

  position: relative;
  top: 0;
  left: -200px;
  right: -50px;
  bottom: 0;
  z-index: -1;

  pointer-events: none;
}

.json-inspector__line:hover:after {
  background: whitesmoke;
}

.json-inspector__leaf_composite > .json-inspector__line {
  cursor: pointer;
}

.json-inspector__radio,
.json-inspector__flatpath {
  display: none;
}

.json-inspector__value {
  margin-left: 5px;
}

.json-inspector__search {
  min-width: 300px;

  padding: 2px;
  font: 16px/1.4 Arial, monospace;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  padding-left: 8px;
  height: 45px;
  border-radius: 4px;
  border-color: lightgray;
  border: 1px solid;
}

.json-inspector__key {
  color: #3f3f3f;
  font-weight: bold;
}

.json-inspector__value_helper,
.json-inspector__value_null,
.json-inspector__not-found {
  color: #b0b0b0;
  display: none;
}

.json-inspector__value_string {
  color: #505050;
}

.json-inspector__value_boolean {
  color: purple;
}

.json-inspector__value_number {
  color: #0069ea;
}

.json-inspector__hl {
  background: #ff0;
  box-shadow: 0 -1px 0 2px #ff0;
  border-radius: 2px;
}

.json-inspector__show-original {
  display: inline-block;
  padding: 0 6px;

  color: #666;
  cursor: pointer;
}

.json-inspector__show-original:hover {
  color: #111;
}

.json-inspector__show-original:before {
  content: "⥂";
}

.json-inspector__show-original:hover:after {
  content: " Expand";
}
